import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IntegrationInstructionsOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FadingTooltip } from '@cosmotech/ui';
import { useGetAssociatedRunnerFromType } from '../../../../../../state/hooks/DatastoreHooks';
import { useDownloadRunLogs } from './DownloadLogsButtonHooks';

const LOG_BUTTON_LABEL_MAP = { validation: 'vérification', push: 'création dataset' };

export const DownloadLogsButton = ({ runnerName }) => {
  const downloadRunLogs = useDownloadRunLogs();
  const getAssociatedRunnerFromType = useGetAssociatedRunnerFromType();
  const runner = useMemo(() => getAssociatedRunnerFromType(runnerName), [runnerName, getAssociatedRunnerFromType]);
  const handleDownloadLogs = useCallback(
    () => downloadRunLogs(runner?.id, runner?.lastRunId),
    [runner, downloadRunLogs]
  );

  return (
    <FadingTooltip title={`(debug) Logs ${LOG_BUTTON_LABEL_MAP[runnerName]}`}>
      <IconButton color="primary" onClick={handleDownloadLogs} disabled={!runner?.id || !runner?.lastRunId}>
        <IntegrationInstructionsOutlined fontSize="large" />
      </IconButton>
    </FadingTooltip>
  );
};

DownloadLogsButton.propTypes = {
  runnerName: PropTypes.string,
};
