import { useCallback } from 'react';
import { Api } from '../../services/config/Api';
import { useOrganizationId } from '../../state/hooks/OrganizationHooks';
import { useWorkspaceId } from '../../state/hooks/WorkspaceHooks';

export const useDataStoreFileDownload = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  return useCallback(
    async (filePath, options = {}) =>
      await Api.Workspaces.downloadWorkspaceFile(organizationId, workspaceId, filePath, options),
    [organizationId, workspaceId]
  );
};
