import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FileDownload } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FileBlobUtils } from '@cosmotech/core';
import { FadingTooltip } from '@cosmotech/ui';
import { useSetApplicationErrorMessage } from '../../../../../../state/hooks/ApplicationHooks';
import {
  useDatasetStorageRoot,
  useIsArchiveAvailable,
  useSelectedDataset,
} from '../../../../../../state/hooks/DatastoreHooks';
import { useDataStoreFileDownload } from '../../../../DataStoreHooks';

export const DownloadZipArchiveButton = () => {
  const { t } = useTranslation();
  const setApplicationErrorMessage = useSetApplicationErrorMessage();
  const downloadFile = useDataStoreFileDownload();
  const storageRoot = useDatasetStorageRoot();
  const isArchiveAvailable = useIsArchiveAvailable();
  const dataset = useSelectedDataset();
  const datasetName = dataset?.name;

  const handleDownloadZipArchive = useCallback(() => {
    downloadFile(`${storageRoot}datastore.zip`, { responseType: 'blob' })
      .then((data) => {
        FileBlobUtils.downloadFileFromData(data.data, `${datasetName.replace(' ', '_')}.zip`);
      })
      .catch((error) => {
        console.error(error);
        setApplicationErrorMessage(
          error,
          t('commoncomponents.datastore.errors.downloadZIPFile', 'A problem occurred when downloading ZIP file.')
        );
      });
  }, [storageRoot, downloadFile, datasetName, t, setApplicationErrorMessage]);

  return (
    <FadingTooltip title="Télécharger au format ZIP">
      <IconButton color="primary" onClick={handleDownloadZipArchive} disabled={!isArchiveAvailable}>
        <FileDownload fontSize="large" />
      </IconButton>
    </FadingTooltip>
  );
};
