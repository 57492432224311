import React from 'react';
import { PlaylistAddCheck } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { FadingTooltip } from '@cosmotech/ui';
import {
  useDatastoreTables,
  useSelectedDataset,
  useStartDatastoreRunner,
} from '../../../../../../state/hooks/DatastoreHooks';

export const StartCheckingButton = () => {
  const startRunner = useStartDatastoreRunner();
  const dataset = useSelectedDataset();
  const datastoreTables = useDatastoreTables();

  const hasNoTables = datastoreTables.length === 0;
  const isPushRunning = dataset?.associatedRunners?.push?.state === 'Running';
  const isValidationRunning = dataset?.associatedRunners?.validation?.state === 'Running';
  const disabled = hasNoTables || isPushRunning || isValidationRunning;

  return (
    <Box>
      {isValidationRunning ? (
        <span style={{ padding: '8px' }}>
          <CircularProgress
            sx={{
              color: 'white',
              height: '24px !important',
              width: '24px !important',
              top: '2px',
              position: 'relative',
            }}
          />
        </span>
      ) : (
        <FadingTooltip title="Lancer la vérification du magasin de données.">
          <IconButton color="primary" onClick={() => startRunner('validation')} disabled={disabled}>
            <PlaylistAddCheck fontSize="large" />
          </IconButton>
        </FadingTooltip>
      )}
    </Box>
  );
};
