import React, { useCallback } from 'react';
import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FadingTooltip } from '@cosmotech/ui';
import { useUploadButton } from './UploadButtonHooks';

export const UploadButton = () => {
  const { fileTypeFilter, tooltipText, uploadFiles } = useUploadButton();

  const openUploadDialog = useCallback(
    async (event) => {
      await uploadFiles(event);
      // Fix Chrome/Edge "cache" behaviour.
      // HTML input is not triggered when the same file is selected twice
      event.target.value = null;
    },
    [uploadFiles]
  );

  return (
    <FadingTooltip title={tooltipText}>
      <IconButton color="primary" onChange={openUploadDialog} data-cy="datastore-upload-button" component="label">
        <input id="datastore-upload-input" type="file" accept={fileTypeFilter} hidden multiple />
        <Add />
      </IconButton>
    </FadingTooltip>
  );
};
