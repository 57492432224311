import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Notifications } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FadingTooltip } from '@cosmotech/ui';
import { useAssociatedPushRunner } from '../../../../../../state/hooks/DatastoreHooks';
import { NotificationsPopOver } from './components';

export const ToggleNotificationsButton = () => {
  const { t } = useTranslation();
  const associatedPushRunner = useAssociatedPushRunner();

  const [open, setOpen] = useState(false);
  const toggleNotificationsPopOver = useCallback(() => setOpen((previousState) => !previousState), [setOpen]);
  const disabled = associatedPushRunner?.lastRunId == null;

  return (
    <>
      <NotificationsPopOver open={open} onClose={() => setOpen(false)} />
      <FadingTooltip title={t('commoncomponents.datastore.notifications.toggle', 'Toggle notifications')}>
        <IconButton color="primary" onClick={toggleNotificationsPopOver} disabled={disabled}>
          <Notifications fontSize="large" />
        </IconButton>
      </FadingTooltip>
    </>
  );
};
