import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelectedDataset } from '../../../../state/hooks/DatastoreHooks';

const useStyles = makeStyles((theme) => ({
  circleBackground: {
    position: 'absolute',
    height: '48px',
    width: '48px',
    backgroundColor: `${theme.palette.tertiary.main}1C`,
    borderRadius: '50%',
    display: 'inline-block',
  },
  icon: {
    position: 'relative',
    top: '12px',
    left: '12px',
    height: '48px',
    width: '48px',
  },
}));

export const DatastoreMetadataCard = ({ icon, label, value }) => {
  const classes = useStyles();
  const dataset = useSelectedDataset();

  if (dataset == null) return null;
  return (
    <Card sx={{ px: 3, py: 4, height: '100%' }}>
      <Grid
        container
        direction="row"
        spacing={5}
        sx={{ flexWrap: 'nowrap', justifyContent: 'flex-start', alignItems: 'stretch' }}
      >
        <Grid item>
          <span className={classes.circleBackground} />
          <span className={classes.icon}>{icon}</span>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            {label && (
              <Grid item>
                <Typography>{label}</Typography>
              </Grid>
            )}
            {value && (
              <Typography sx={{ maxHeight: '2lh', overflow: 'auto', wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
                <strong>{value}</strong>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

DatastoreMetadataCard.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.string,
};
