import React, { useCallback } from 'react';
import { NoteAddOutlined } from '@mui/icons-material';
import { CircularProgress, IconButton, Box } from '@mui/material';
import { FadingTooltip } from '@cosmotech/ui';
import {
  useDatastoreTables,
  useResetValidationState,
  useValidationState,
  useAssociatedRunners,
  useStartDatastoreRunner,
} from '../../../../../../state/hooks/DatastoreHooks';

export const PushDataStoreButton = () => {
  const validationState = useValidationState();
  const resetValidationState = useResetValidationState();
  const startRunner = useStartDatastoreRunner();
  const associatedRunners = useAssociatedRunners();
  const datastoreTables = useDatastoreTables();

  const hasNoTables = datastoreTables.length === 0;
  const isPushRunning = associatedRunners?.push?.state === 'Running';
  const isValidationRunning = associatedRunners?.validation?.state === 'Running';
  const disabled = hasNoTables || validationState?.status !== 'valid' || isPushRunning || isValidationRunning;

  const startValidation = useCallback(() => {
    startRunner('push');
    resetValidationState();
  }, [resetValidationState, startRunner]);

  return (
    <Box sx={{ position: 'relative' }}>
      {isPushRunning ? (
        <span style={{ padding: '8px' }}>
          <CircularProgress
            sx={{
              color: 'white',
              height: '24px !important',
              width: '24px !important',
              top: '2px',
              position: 'relative',
            }}
          />
        </span>
      ) : (
        <>
          <FadingTooltip title="Création d'un Dataset à partir du magasin de données">
            <IconButton color="primary" onClick={() => startValidation()} disabled={disabled}>
              <NoteAddOutlined fontSize="large" />
            </IconButton>
          </FadingTooltip>
        </>
      )}
    </Box>
  );
};
