import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoAwesomeOutlined, DescriptionOutlined, PermIdentityOutlined } from '@mui/icons-material';
import { Box, Typography, Backdrop, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import i18next from 'i18next';
import {
  useDatastoreFiles,
  useFetchAllWorkspaceFiles,
  useGetWorkspaceFiles,
  useIsLoading,
  useSelectedDataset,
  useSetLoading,
  useResetValidationState,
  useSetValidationState,
  useUpdateValidationState,
  useValidationState,
} from '../../state/hooks/DatastoreHooks';
import { useDataStoreFileDownload } from './DataStoreHooks';
import {
  ActionsToolbar,
  DataStoreItemsList,
  DataStoreItemDetails,
  DatastoreMetadataCard,
  DataStoreTablesList,
  StatusChip,
} from './components';

const DataStore = () => {
  const { t } = useTranslation();
  const loading = useIsLoading();
  const setLoading = useSetLoading();
  const validationState = useValidationState();
  const resetValidationState = useResetValidationState();
  const setValidationState = useSetValidationState();
  const updateValidationState = useUpdateValidationState();

  const dataset = useSelectedDataset();
  const datastoreFiles = useDatastoreFiles();

  const isMounted = useRef(true);

  const downloadFile = useDataStoreFileDownload();
  const fetchAllFiles = useFetchAllWorkspaceFiles();
  const workspaceFiles = useGetWorkspaceFiles();

  useEffect(() => {
    return () => {
      setLoading(true);
      resetValidationState();
    };
  }, [dataset?.id, setLoading, resetValidationState]);

  useEffect(() => {
    const applyEffect = async () => {
      isMounted.current = true;

      const validationFile = datastoreFiles.find((file) => file.name === '_validation.json');

      if (!validationFile) {
        updateValidationState({ status: 'draft' });
        setLoading(false);
        return;
      }

      try {
        if (validationState?.status == null || validationState?.status === 'draft') {
          const { data } = await downloadFile(validationFile.storagePath);
          if (isMounted.current) setValidationState(data);
        }
      } catch (error) {
        console.error('Error downloading validation file:', error);
      } finally {
        setLoading(false);
      }
    };

    applyEffect();
  }, [datastoreFiles, downloadFile, setLoading, setValidationState, updateValidationState, validationState?.status]);

  useEffect(() => {
    isMounted.current = true;
    if (workspaceFiles.length === 0) {
      setLoading(true);
      fetchAllFiles();
    }

    return () => {
      isMounted.current = false;
      resetValidationState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const datastoreMetadata = useMemo(() => {
    if (dataset == null) return null;

    const desriptionGridItemsWidth = 6;
    const otherGridItemsWidth = dataset.description != null ? 3 : 6;

    return (
      <Grid container direction="row" spacing={2} alignItems="stretch">
        {dataset.description && (
          <Grid item xs={desriptionGridItemsWidth}>
            <DatastoreMetadataCard
              icon={<DescriptionOutlined color="tertiary" />}
              label={t('commoncomponents.datastore.details.metadata.description', 'Description')}
              value={dataset.description}
            />
          </Grid>
        )}
        <Grid item xs={otherGridItemsWidth}>
          <DatastoreMetadataCard
            icon={<PermIdentityOutlined color="tertiary" />}
            label={t('commoncomponents.datastore.details.metadata.owner', 'Owner')}
            value={dataset.ownerName}
          />
        </Grid>
        <Grid item xs={otherGridItemsWidth}>
          <DatastoreMetadataCard
            icon={<AutoAwesomeOutlined color="tertiary" />}
            label={t('commoncomponents.datastore.details.metadata.creationDate', 'Creation date')}
            value={new Date(dataset.creationDate).toLocaleString(i18next?.resolvedLanguage, {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          />
        </Grid>
      </Grid>
    );
  }, [t, dataset]);

  const noSelectionPlaceholder = useMemo(() => {
    if (dataset != null) return null;
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <Typography variant="h6">
          {t('commoncomponents.datastore.details.placeholder.text', 'Please select a datastore to work with')}
        </Typography>
      </Box>
    );
  }, [t, dataset]);

  return (
    <Grid
      container
      direction="column"
      sx={{ gap: 3, height: '100%', p: 2, overflow: 'auto', display: 'flex' }}
      wrap="nowrap"
    >
      <Backdrop
        data-cy="datastore-view-backdrop"
        open={loading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
      <Grid item id="top">
        <Grid container sx={{ justifyContent: 'space-between' }} wrap="wrap">
          <Grid container item sx={{ flex: '4 1 0', alignItems: 'baseline', p: 1 }}>
            <DataStoreItemsList />
            <StatusChip />
          </Grid>
          <Grid item sx={{ flex: '4 1 0' }}>
            <ActionsToolbar />
          </Grid>
        </Grid>
      </Grid>
      <Grid item id="datastore-metadata">
        {datastoreMetadata}
      </Grid>
      <Grid item id="validation-details">
        <DataStoreItemDetails />
        {noSelectionPlaceholder}
      </Grid>
      <Grid item id="files-list" sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <DataStoreTablesList />
      </Grid>
    </Grid>
  );
};

export default DataStore;
