import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckCircle, Close, Download, Error } from '@mui/icons-material';
import { Button, Card, CardContent, Divider, Fade, IconButton, Popper, Stack, Typography } from '@mui/material';
import i18next from 'i18next';
import { useAssociatedPushRunner } from '../../../../../../../../state/hooks/DatastoreHooks';
import { useDownloadRunLogs } from '../../../DownloadLogsButton/DownloadLogsButtonHooks';

export const NotificationsPopOver = ({ open, onClose }) => {
  const { t } = useTranslation();
  const associatedPushRunner = useAssociatedPushRunner();
  const downloadRunLogs = useDownloadRunLogs();
  const anchorEl = document.getElementById('datastore-notifications-panel-anchor');

  const handleDownloadLogs = useCallback(
    () => downloadRunLogs(associatedPushRunner?.id, associatedPushRunner?.lastRunId),
    [associatedPushRunner, downloadRunLogs]
  );

  const { creationDateLabel, lastRunDate, statusIcon } = useMemo(() => {
    const lastPushStatus = associatedPushRunner?.state;
    const lastRunDate = associatedPushRunner?.lastRunDate
      ? new Date(associatedPushRunner?.lastRunDate).toLocaleString(i18next?.resolvedLanguage, {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      : null;

    let statusIcon = null;
    let creationDateLabel = null;
    if (lastPushStatus === 'Successful') {
      statusIcon = <CheckCircle sx={{ m: 1 }} color="success" />;
      creationDateLabel = t(
        'commoncomponents.datastore.notifications.creationDateLabel.success',
        'Dataset creation succeeded'
      );
    } else if (lastPushStatus === 'Failed') {
      statusIcon = <Error sx={{ m: 1 }} color="error" />;
      creationDateLabel = t(
        'commoncomponents.datastore.notifications.creationDateLabel.fail',
        'Dataset creation failed'
      );
    }

    return { creationDateLabel, lastRunDate, statusIcon };
  }, [t, associatedPushRunner]);

  useEffect(() => {
    if (onClose && associatedPushRunner?.lastRunId == null) onClose();
  }, [associatedPushRunner, onClose]);

  return (
    <>
      <span
        id="datastore-notifications-panel-anchor"
        style={{ position: 'absolute', top: '8px', right: '8px', width: 0, height: 0 }}
      />
      {associatedPushRunner?.lastRunId == null ? null : (
        <Popper open={open} anchorEl={anchorEl} placement="right-end" style={{ zIndex: 99999 }} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Card>
                <CardContent sx={{ p: '0 !important', minWidth: '450px' }}>
                  <Stack>
                    <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center', py: 2, px: 3 }}>
                      <Typography variant="h6">
                        {t('commoncomponents.datastore.notifications.label', 'Notifications')}
                      </Typography>
                      {onClose && (
                        <IconButton color="inherit" onClick={onClose}>
                          <Close />
                        </IconButton>
                      )}
                    </Stack>
                    <Divider />
                    <Stack direction="row" sx={{ py: 2, px: 3, justifyContent: 'space-between' }}>
                      <Stack direction="row" sx={{ gap: 1 }}>
                        {statusIcon}
                        <Stack>
                          <Typography variant="body1">{creationDateLabel}</Typography>
                          <Typography variant="subtitle2" color="textSecondary">
                            {lastRunDate}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Button startIcon={<Download />} onClick={handleDownloadLogs}>
                        {t('commoncomponents.datastore.notifications.downloadLogs', 'Logs')}
                      </Button>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Fade>
          )}
        </Popper>
      )}
    </>
  );
};

NotificationsPopOver.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

NotificationsPopOver.defaultProps = {
  open: false,
};
