import React from 'react';
import { Error, ExpandMore } from '@mui/icons-material';
import { List, ListItem, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useValidationState } from '../../../../state/hooks/DatastoreHooks';

export const DataStoreItemDetails = () => {
  const validation = useValidationState();

  return (
    validation.details.length > 0 && (
      <Accordion>
        <AccordionSummary sx={{ color: 'error' }} expandIcon={<ExpandMore />}>
          <Error color="error" />
          <Typography color="error">{validation.details.length} erreur(s) de validation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
            {validation.details.map((detail) => (
              <ListItem key={detail} sx={{ display: 'list-item' }}>
                {detail}
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    )
  );
};
