import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FactCheckOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FileBlobUtils } from '@cosmotech/core';
import { FadingTooltip } from '@cosmotech/ui';
import { useSetApplicationErrorMessage } from '../../../../../../state/hooks/ApplicationHooks';
import {
  useDatasetStorageRoot,
  useIsReportAvailable,
  useSelectedDataset,
} from '../../../../../../state/hooks/DatastoreHooks';
import { useDataStoreFileDownload } from '../../../../DataStoreHooks';

export const DownloadValidationReportButton = () => {
  const { t } = useTranslation();
  const setApplicationErrorMessage = useSetApplicationErrorMessage();
  const downloadFile = useDataStoreFileDownload();
  const storageRoot = useDatasetStorageRoot();
  const isReportAvailable = useIsReportAvailable();
  const dataset = useSelectedDataset();
  const datasetName = dataset?.name;

  const handleDownloadReport = useCallback(() => {
    downloadFile(`${storageRoot}validation_report.txt`)
      .then((data) => {
        FileBlobUtils.downloadFileFromData(data.data, `${datasetName.replace(' ', '_')}_validation_report.txt`);
      })
      .catch((error) => {
        console.error(error);
        setApplicationErrorMessage(
          error,
          t(
            'commoncomponents.datastore.errors.downloadValidationReport',
            'A problem occurred when downloading validation report.'
          )
        );
      });
  }, [storageRoot, downloadFile, datasetName, t, setApplicationErrorMessage]);

  return (
    <FadingTooltip title="Télécharger le rapport de validation">
      <IconButton color="primary" onClick={handleDownloadReport} disabled={!isReportAvailable}>
        <FactCheckOutlined fontSize="large" />
      </IconButton>
    </FadingTooltip>
  );
};
