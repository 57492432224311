import React, { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { FileBlobUtils } from '@cosmotech/core';
import { UPLOAD_FILE_STATUS_KEY } from '@cosmotech/ui';
import { WizardUtils } from '../../../utils/WizardUtils';
import { useVecteurDonnees } from './hooks/VecteurDonneesHook';

const VecteurDonnees = ({ context }) => {
  const { addTechVar, assetClass, equipments, scenarioId } = useVecteurDonnees();
  const equipmentsNames = useMemo(() => {
    return Object.keys(equipments ?? {});
  }, [equipments]);

  const exportCsv = useCallback(
    (rows) => {
      const fileContent = WizardUtils.createFileContent(rows);
      FileBlobUtils.downloadFileFromData(fileContent, `${assetClass}.csv`);
    },
    [assetClass]
  );

  if (Object.keys(equipments ?? {}).length === 0 && Object.values(equipments ?? {}).length === 0)
    return <span>Aucune donnée à afficher.</span>;
  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} sm={6} style={{ marginTop: '10px' }}>
          <Autocomplete
            options={equipmentsNames}
            value={equipmentsNames.find((option) => option === (assetClass ?? '')) || null}
            onChange={(event, newValue) => {
              event.preventDefault();
              event.stopPropagation();
              addTechVar({ assetClass: newValue }, scenarioId);
            }}
            renderInput={(params) => <TextField {...params} label="Asset Class" fullWidth />}
          />
        </Grid>
        {assetClass && (
          <Controller
            name={assetClass}
            render={({ field }) => {
              const { value, onChange } = field;
              const baseValue = equipments[assetClass];
              return (
                <>
                  <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!assetClass}
                      onClick={() => exportCsv(value?.rows ?? baseValue)}
                    >
                      Télécharger le CSV
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      component="label"
                      disabled={!assetClass || !context.editMode}
                      onChange={(event) => {
                        const file = event.target.files[0];
                        event.target.value = null;
                        const reader = new FileReader();
                        reader.onload = function (event) {
                          const fileContent = event.target.result;
                          const parsedAssetClassFile = WizardUtils.parseAssetClassCsvFile(baseValue, fileContent);
                          onChange({
                            ...(value ?? {}),
                            name: `${assetClass}.csv`,
                            status: UPLOAD_FILE_STATUS_KEY.READY_TO_UPLOAD,
                            rows: parsedAssetClassFile,
                          });
                        };
                        reader.readAsText(file);
                      }}
                    >
                      Charger un nouveau CSV
                      <input accept=".csv" style={{ display: 'none' }} id="upload-csv-file" type="file" />
                    </Button>
                  </Grid>
                </>
              );
            }}
          />
        )}
      </Grid>
    </div>
  );
};

VecteurDonnees.propTypes = {
  context: PropTypes.object,
};

export default VecteurDonnees;
