import { t } from 'i18next';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { Api } from '../../../../services/config/Api';
import { DATASET_ACTIONS_KEY } from '../../../commons/DatasetConstants';
import { DATASTORE_ACTIONS } from '../../../commons/DatastoreConstants';
import { RUNNER_ACTIONS_KEY } from '../../../commons/RunnerConstants';
import { dispatchSetApplicationErrorMessage } from '../../../dispatchers/app/ApplicationDispatcher';
import { createDataset } from '../../datasets/CreateDataset';
import { updateDataset } from '../../datasets/UpdateDataset';

const getUserEmail = (state) => state.auth.userEmail;

export function* createDatastoreRunners(action) {
  try {
    const organizationId = action.organizationId;
    const workspaceId = action.workspaceId;
    const userEmail = yield select(getUserEmail);
    // Setting default role to admin for now until the share button is implemented
    const defaultAdminSecurity = { default: 'admin', accessControlList: [{ id: userEmail, role: 'admin' }] };
    const dataset = {
      ...action.dataset,
      tags: ['datastore'],
      sourceType: 'None',
      security: defaultAdminSecurity,
    };
    const { id: datasetId } = yield call(createDataset, { dataset, organizationId, selected: false });
    const source = { name: dataset.name, location: organizationId, path: `${workspaceId}/datastore/${datasetId}` };
    const updatedDataset = { ...dataset, source, sourceType: 'AzureStorage' };
    yield call(updateDataset, { organizationId, datasetId, datasetData: updatedDataset });

    const runner = { ...action.dataset };
    runner.security = defaultAdminSecurity;
    runner.runTemplateId = 'datastore_checking';
    runner.parametersValues = [{ parameterId: 'source_dataset_id', value: datasetId }];
    delete runner.sourceType;

    const { data: runnerForCheck } = yield call(Api.Runners.createRunner, organizationId, workspaceId, runner);
    yield put({ type: DATASTORE_ACTIONS.ADD_RUNNER, runner: runnerForCheck });

    runner.runTemplateId = 'push_datastore';
    const { data: runnerForPush } = yield call(Api.Runners.createRunner, organizationId, workspaceId, runner);
    yield put({ type: DATASTORE_ACTIONS.ADD_RUNNER, runner: runnerForPush });

    yield put({
      type: DATASET_ACTIONS_KEY.UPDATE_DATASET,
      datasetId,
      datasetData: {
        associatedRunners: {
          validation: {
            id: runnerForCheck.id,
            lastRunId: null,
            lastRunDate: null,
            state: null,
          },
          push: {
            id: runnerForPush.id,
            lastRunId: null,
            lastRunDate: null,
            state: null,
          },
        },
      },
    });

    yield put({ type: DATASTORE_ACTIONS.SELECT_DATASTORE, datasetId });
  } catch (error) {
    console.error(error);
    yield put(
      dispatchSetApplicationErrorMessage(
        error,
        t('commoncomponents.banner.runnerNotCreated', "Runner hasn't been created")
      )
    );
  }
}

export function* createDatastoreAndRunnersSaga() {
  yield takeEvery(RUNNER_ACTIONS_KEY.TRIGGER_SAGA_CREATE_DATASTORE_AND_RUNNERS, createDatastoreRunners);
}
