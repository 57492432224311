import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../../../../../services/config/Api';
import { useSetApplicationErrorMessage } from '../../../../../../state/hooks/ApplicationHooks';
import { useDeleteDataset } from '../../../../../../state/hooks/DatasetHooks';
import {
  useAssociatedRunners,
  useDatasetStorageRoot,
  useDeleteDatastoreFiles,
  useDeleteRunner,
  useSelectedDataset,
  useGetWorkspaceFiles,
} from '../../../../../../state/hooks/DatastoreHooks';
import { useOrganizationId } from '../../../../../../state/hooks/OrganizationHooks';
import { useWorkspaceId } from '../../../../../../state/hooks/WorkspaceHooks';

export const useDeleteDatastore = () => {
  const { t } = useTranslation();
  const setApplicationErrorMessage = useSetApplicationErrorMessage();
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  const deleteRunner = useDeleteRunner();
  const dataset = useSelectedDataset();
  const datasetStorageRoot = useDatasetStorageRoot();
  const workspaceFiles = useGetWorkspaceFiles();
  const deleteFiles = useDeleteDatastoreFiles();
  const deleteDataset = useDeleteDataset();
  const associatedRunners = useAssociatedRunners();

  return useCallback(
    async () => {
      try {
        if (datasetStorageRoot == null) {
          throw new Error('No dataset defined: cannot remove workspace files');
        }
        const filesToDelete = workspaceFiles.filter((file) => file.startsWith(datasetStorageRoot));
        deleteFiles(filesToDelete);
        const runnersList = Object.values(associatedRunners ?? {});
        await Promise.all(
          runnersList.map((runner) => {
            deleteRunner(runner.id);
            return Api.Runners.deleteRunner(organizationId, workspaceId, runner.id);
          })
        );

        deleteDataset(dataset.id);
      } catch (error) {
        console.error(error);
        setApplicationErrorMessage(
          error,
          t('commoncomponents.datastore.errors.delete', 'A problem occurred during data store delete.')
        );
      }
    },
    [
      associatedRunners,
      organizationId,
      workspaceId,
      dataset,
      datasetStorageRoot,
      deleteDataset,
      deleteFiles,
      deleteRunner,
      t,
      setApplicationErrorMessage,
      workspaceFiles,
    ],
    t
  );
};
