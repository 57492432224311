import React, { useCallback, useState } from 'react';
import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FadingTooltip } from '@cosmotech/ui';
import { CreateDataStoreItemWizard } from './components/CreateDataStoreItemWizard';

export const CreateDataStoreItemButton = () => {
  const [isCreationWizardOpen, setIsCreationWizardOpen] = useState(false);
  const handleCloseDialog = useCallback(() => {
    setIsCreationWizardOpen(false);
  }, [setIsCreationWizardOpen]);

  return (
    <>
      <FadingTooltip title="Créer un magasin de données.">
        <IconButton
          color="primary"
          onClick={() => setIsCreationWizardOpen(true)}
          data-cy="create-dataset-button"
          sx={{ height: '100%' }}
        >
          <Add fontSize="large" />
        </IconButton>
      </FadingTooltip>
      <CreateDataStoreItemWizard open={isCreationWizardOpen} closeDialog={handleCloseDialog} />
    </>
  );
};
