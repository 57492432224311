import React, { useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { BasicTextInput } from '@cosmotech/ui';
import { ArrayDictUtils } from '../../../../../../../../utils';
import { useCreateDataStoreItemWizard } from './CreateDataStoreItemWizardHook';

export const CreateDataStoreItemWizard = ({ open, closeDialog }) => {
  const { t } = useTranslation();
  const createDataStoreRunner = useCreateDataStoreItemWizard();

  const methods = useForm({ mode: 'onChange' });
  const { formState } = methods;

  useEffect(() => {
    if (open) {
      methods.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const createDatasetAndCloseDialog = useCallback(() => {
    const values = methods.getValues();
    ArrayDictUtils.removeUndefinedValuesFromDict(values);

    const dataset = {
      name: values.name,
      description: values.description,
    };
    createDataStoreRunner(dataset);
    closeDialog();
  }, [closeDialog, methods, createDataStoreRunner]);

  return (
    <FormProvider {...methods}>
      <Dialog open={open} fullWidth data-cy="dataset-creation-dialog">
        <DialogTitle>{t('commoncomponents.datastore.wizard.title', 'Create dataset')}</DialogTitle>
        <DialogContent>
          <Grid container gap={1}>
            <Grid item xs={12}>
              <Typography sx={{ py: 2 }}>
                {t(
                  'commoncomponents.datastore.wizard.firstScreen.title',
                  'Please provide some metadata regarding your new dataset'
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="name"
                rules={{ required: true }}
                render={({ field }) => {
                  const { value: titleValue, onChange: setTitleValue } = field;
                  return (
                    <BasicTextInput
                      id="new-dataset-title"
                      label={t('commoncomponents.datastore.wizard.firstScreen.name', 'Name')}
                      value={titleValue ?? ''}
                      changeTextField={(newValue) => setTitleValue(newValue)}
                      size="medium"
                    ></BasicTextInput>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                render={({ field }) => {
                  const { value: descriptionValue, onChange: setDescriptionValue } = field;
                  return (
                    <BasicTextInput
                      id="new-dataset-description"
                      label={t('commoncomponents.datastore.wizard.firstScreen.description', 'Description')}
                      value={descriptionValue ?? ''}
                      changeTextField={(newValue) => setDescriptionValue(newValue)}
                      size="medium"
                      textFieldProps={{
                        multiline: true,
                        rows: 3,
                      }}
                    ></BasicTextInput>
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} data-cy="cancel-dataset-creation">
            {t('commoncomponents.datasetmanager.dialogs.cancel', 'Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!formState.isValid}
            onClick={createDatasetAndCloseDialog}
            data-cy="confirm-dataset-creation"
          >
            {t('commoncomponents.datastore.wizard.buttons.create', 'Create')}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

CreateDataStoreItemWizard.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};
