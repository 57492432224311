import { Gantt, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Importation de PropTypes pour la validation des props
import { Box } from '@mui/material';
import './Gantt.css';
import { useGantt } from './GanttHook';

// Personnalisation de l'en-tête de la liste des tâches
const CustomTaskListHeader = () => {
  return (
    <div className="ganttTable_Header ganttTable_Header_custom">
      <div style={{ width: '200px' }}>Gisements</div>
    </div>
  );
};

// Personnalisation de la table des tâches
const CustomTaskListTable = ({ rowHeight, tasks, setSelectedTask, handleGisementClick }) => {
  return (
    <div className="ganttTable_TBody">
      {tasks.map((task) => (
        <div
          key={task.id}
          className={`ganttTable_TBody-row ${task.type === 'project' ? 'gisement-row' : 'regle-row'}`}
          style={{ height: rowHeight, cursor: task.type === 'project' ? 'pointer' : 'default' }}
          onClick={() => {
            if (task.type === 'project') {
              handleGisementClick(task.id); // Gérer le collapse/expand des gisements
            } else {
              setSelectedTask(task.id); // Sélectionner une tâche
            }
          }}
        >
          <div
            data-testid={`${task.type}-${task.name}`}
            style={{ width: '200px', display: 'flex', alignItems: 'center' }}
          >
            {task.type === 'project' && (
              <span className={`expander ${task.hideChildren ? 'closed' : 'open'}`} style={{ marginRight: '10px' }}>
                {task.hideChildren ? '▶' : '▼'} {/* Icône pour indiquer le collapse/expand */}
              </span>
            )}
            {task.name}
          </div>
        </div>
      ))}
    </div>
  );
};

CustomTaskListTable.propTypes = {
  rowHeight: PropTypes.number.isRequired, // Hauteur de la ligne, attendu en nombre
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired, // L'ID de la tâche doit être une chaîne
      name: PropTypes.string.isRequired, // Le nom de la tâche
      type: PropTypes.string.isRequired, // Le type de la tâche (project ou regle)
      hideChildren: PropTypes.bool, // Optionnel : booléen pour indiquer si les enfants sont cachés
    })
  ).isRequired,
  setSelectedTask: PropTypes.func.isRequired, // Fonction pour définir la tâche sélectionnée
  handleGisementClick: PropTypes.func.isRequired, // Fonction pour gérer le clic sur les gisements
};

const ReglesGestionGantt = () => {
  const [viewMode] = useState(ViewMode.Year); // Mode de vue par défaut (année)
  const { tasks: tasksData } = useGantt();
  const [tasks, setTasks] = useState(tasksData); // Initialisation des tâches à partir d'un helper
  const [isChecked] = useState(true); // Contrôle si certaines options (comme les colonnes) sont affichées
  const columnWidth = 100; // Largeur des colonnes du Gantt
  // Mise à jour des tâches quand une expander (flèche collapse/expand) est cliquée
  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  // Gérer le collapse/expand des gisements
  const handleGisementClick = (gisementId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => {
        if (task.id === gisementId) {
          return { ...task, hideChildren: !task.hideChildren }; // Inverser l'état de hideChildren
        }
        return task;
      })
    );
  };

  // Update tasks when tasksData changes to rerender gantt chart
  // on start or end date change for example
  useEffect(() => {
    setTasks(tasksData);
  }, [tasksData]);

  return (
    <Box sx={{ padding: '20px' }} className="gantt-container">
      {tasks && (
        <Gantt
          tasks={tasks}
          viewMode={viewMode}
          onExpanderClick={handleExpanderClick}
          arrowColor={'transparent'} // Cacher les flèches par défaut entre les tâches
          listCellWidth={isChecked ? '155px' : ''} // Largeur conditionnelle des cellules de liste
          columnWidth={columnWidth} // Largeur des colonnes du diagramme Gantt
          TaskListHeader={CustomTaskListHeader} // Utilisation de l'en-tête personnalisé
          TaskListTable={(props) => {
            return <CustomTaskListTable {...props} handleGisementClick={handleGisementClick} />;
          }} // Utilisation de la table personnalisée avec gestion du collapse/expand
        />
      )}
    </Box>
  );
};

ReglesGestionGantt.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired, // ID unique pour chaque tâche
      name: PropTypes.string.isRequired, // Nom de la tâche
      start: PropTypes.instanceOf(Date).isRequired, // Date de début de la tâche
      end: PropTypes.instanceOf(Date).isRequired, // Date de fin de la tâche
      type: PropTypes.string.isRequired, // Type de la tâche (project, task, etc.)
      hideChildren: PropTypes.bool, // Optionnel : cacher les enfants ou non
    })
  ),
  viewMode: PropTypes.oneOf(Object.values(ViewMode)), // Mode de vue du Gantt (ex: Year, Month)
};

export default ReglesGestionGantt;
