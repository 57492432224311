import React from 'react';
import PropTypes from 'prop-types';
import { NewReleases, CheckCircle, Cancel, Help } from '@mui/icons-material';

const fileStatusIcons = {
  valid: <CheckCircle color="success" />,
  error: <Cancel color="error" />,
  draft: <Help color="default" />,
  modified: <NewReleases color="tertiary" />,
};

export const FileStatusIcon = ({ status }) => fileStatusIcons[status] ?? <Help color="default" />;

FileStatusIcon.propTypes = {
  status: PropTypes.string,
};
