import React from 'react';
import PropTypes from 'prop-types';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails, Grid, List, ListItem, Typography, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FileStatusIcon } from '../../../FileStatusIcon';

const useStyles = makeStyles((theme) => ({
  error: { backgroundColor: `${theme.palette.error.main}0D` },
  valid: { backgroundColor: `${theme.palette.success.main}0D` },
  modified: { backgroundColor: `${theme.palette.tertiary.main}0D` },
  draft: { backgroundColor: `${theme.palette.info.main}0D` },
}));

export const TableErrorDetails = ({ validation }) => {
  const classes = useStyles();
  const getClassName = (status) => classes?.[status] ?? classes?.draft;

  const getStatusMessage = (validation) => {
    const messages = {
      error: <Typography color="error.main">{validation.details?.length} erreur(s) de validation</Typography>,
      valid: <Typography color="success.main">Table valide</Typography>,
      modified: <Typography color="tertiary.main">Cette table a été modifiée et doit être validée</Typography>,
      draft: <Typography>Cette table n&#39;a pas encore été validée</Typography>,
    };

    return (
      <Grid container style={{ gap: '16px', alignItems: 'center' }}>
        <FileStatusIcon status={validation.status} />
        <span style={{ position: 'relative', top: '2px' }}>{messages[validation.status || 'draft']}</span>
      </Grid>
    );
  };

  return (
    <>
      {validation.details?.length > 0 ? (
        <Accordion>
          <AccordionSummary className={getClassName(validation.status)} expandIcon={<ExpandMore />}>
            {getStatusMessage(validation)}
          </AccordionSummary>
          <AccordionDetails>
            <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
              {validation.details.map((detail) => (
                <ListItem key={detail} sx={{ display: 'list-item' }}>
                  {typeof detail === 'string' ? detail : JSON.stringify(detail)}
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Card className={getClassName(validation.status)} sx={{ display: 'flex', p: 1.5, overflow: 'unset' }}>
          {getStatusMessage(validation)}
        </Card>
      )}
    </>
  );
};

TableErrorDetails.propTypes = {
  validation: PropTypes.object.isRequired,
};
