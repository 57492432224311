import React from 'react';
import { Grid } from '@mui/material';
import ShareDatasetButton from '../../../../components/ShareDatasetButton/ShareDatasetButton';
import { useSelectedDataset } from '../../../../state/hooks/DatastoreHooks';
import {
  CreateDataStoreItemButton,
  DeleteDatastoreButton,
  DownloadLogsButton,
  DownloadValidationReportButton,
  DownloadZipArchiveButton,
  PushDataStoreButton,
  StartCheckingButton,
  ToggleNotificationsButton,
} from './components';

export const ActionsToolbar = () => {
  const dataset = useSelectedDataset() ?? {};
  return (
    <Grid
      container
      sx={{ gap: 0, p: 1 }}
      wrap="wrap"
      justifyContent="flex-end"
      alignContent="flex-start"
      alignItems="center"
    >
      <CreateDataStoreItemButton />
      <StartCheckingButton />
      <PushDataStoreButton />
      <ToggleNotificationsButton />
      <ShareDatasetButton dataset={dataset} />
      <DownloadZipArchiveButton />
      <DownloadValidationReportButton />
      <DownloadLogsButton runnerName="validation" />
      <DeleteDatastoreButton />
    </Grid>
  );
};
