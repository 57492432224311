import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as JSZip from 'jszip';
import rfdc from 'rfdc';
import {
  useDatastoreTables,
  useDatasetStorageRoot,
  useDeleteDatastoreFiles,
  useIsArchiveAvailable,
  useIsLoading,
  useIsReportAvailable,
  useSetLoading,
  useSetValidationState,
  useUploadDatastoreFiles,
  useValidationState,
} from '../../../../../../state/hooks/DatastoreHooks';

const clone = rfdc();

export const useUploadButton = () => {
  const { t } = useTranslation();
  const datastoreTables = useDatastoreTables();
  const isDatastoreLoading = useIsLoading();
  const isArchiveAvailable = useIsArchiveAvailable();
  const isReportAvailable = useIsReportAvailable();
  const storageRoot = useDatasetStorageRoot();
  const validation = useValidationState();

  const deleteFiles = useDeleteDatastoreFiles();
  const uploadDatastoreFiles = useUploadDatastoreFiles();
  const setLoading = useSetLoading();
  const setValidationState = useSetValidationState();

  const { allowZipUpload, tooltipText, fileTypeFilter } = useMemo(() => {
    const allowZipUpload = !isDatastoreLoading && datastoreTables.length === 0;
    const tooltipText = allowZipUpload
      ? t('commoncomponents.datastore.upload.uploadTables', 'Upload datastore tables (zip or csv files)')
      : t('commoncomponents.datastore.upload.uploadCSVTables', 'Upload datastore tables (csv files)');
    const fileTypeFilter = allowZipUpload ? '.zip,.ZIP,.csv,.CSV' : '.csv,.CSV';
    return { allowZipUpload, tooltipText, fileTypeFilter };
  }, [datastoreTables, isDatastoreLoading, t]);

  const uploadZipFile = useCallback(
    async (zipFile) => {
      const zip = new JSZip();
      const archiveContent = await zip.loadAsync(zipFile);
      const fileNames = Object.keys(archiveContent.files).filter(
        (filename) => !filename.endsWith('/') && filename.toLowerCase().endsWith('.csv')
      );

      const filesToUpload = [];
      for (const filename of fileNames) {
        const fileBlob = await zip.file(filename).async('blob');
        // Flattening fileName to upload file and not the whole zip structure including folders
        // implies that if 2 or more files share the same name in different directories in
        // the zip, the last uploaded one will override the others.
        const flatFileName = filename.split('/').pop();
        const fileData = new File([fileBlob], flatFileName);
        filesToUpload.push({ data: fileData, path: storageRoot });
      }
      uploadDatastoreFiles(filesToUpload);
    },
    [uploadDatastoreFiles, storageRoot]
  );

  const uploadCsvFiles = useCallback(
    async (csvFiles) => {
      const filesToUpload = [];
      const updatedValidation = clone(validation);
      updatedValidation.status = validation.status ? 'modified' : 'draft';
      for (const file of csvFiles) {
        filesToUpload.push({ data: file, path: storageRoot });
        updatedValidation.tables[file.name] = {
          status: Object.keys(updatedValidation.tables).includes(file.name) ? 'modified' : 'draft',
          details: [],
        };
      }

      const validationBlob = new Blob([JSON.stringify(updatedValidation)]);
      const validationFile = new File([validationBlob], '_validation.json');
      filesToUpload.push({ data: validationFile, path: storageRoot });

      const filesToDelete = [];
      if (isArchiveAvailable) filesToDelete.push(`${storageRoot}datastore.zip`);
      if (isReportAvailable) filesToDelete.push(`${storageRoot}validation_report.txt`);

      uploadDatastoreFiles(filesToUpload);
      if (filesToDelete.length > 0) deleteFiles(filesToDelete);
      setValidationState(updatedValidation);
    },
    [
      deleteFiles,
      isArchiveAvailable,
      isReportAvailable,
      setValidationState,
      storageRoot,
      uploadDatastoreFiles,
      validation,
    ]
  );

  const uploadFiles = useCallback(
    async (event) => {
      const allowedExtensions = fileTypeFilter.split(',');
      const selectedFiles = event.target.files;
      if (selectedFiles == null || selectedFiles.length === 0) return;
      setLoading(true);
      const files = Array.from(selectedFiles).filter((file) =>
        allowedExtensions.some((extension) => file.name.endsWith(extension))
      );

      try {
        if (allowZipUpload)
          await uploadZipFile(files[0]); // Upload only the first file if multiple zip files are provided
        else await uploadCsvFiles(files);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [allowZipUpload, fileTypeFilter, setLoading, uploadCsvFiles, uploadZipFile]
  );

  return { fileTypeFilter, tooltipText, uploadFiles };
};
