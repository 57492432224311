import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FadingTooltip } from '@cosmotech/ui';
import { TwoActionsDialogService } from '../../../../../../services/twoActionsDialog/twoActionsDialogService';
import { useAssociatedRunners, useSelectedDataset } from '../../../../../../state/hooks/DatastoreHooks';
import { useDeleteDatastore } from './DeleteDatastoreButtonHooks';

export const DeleteDatastoreButton = () => {
  const { t } = useTranslation();
  const associatedRunners = useAssociatedRunners();
  const dataset = useSelectedDataset();
  const deleteDataStore = useDeleteDatastore();
  const isRunning = associatedRunners?.push?.state === 'Running' || associatedRunners?.validation?.state === 'Running';
  const disabled = dataset == null || isRunning;

  const askConfirmationToDeleteDialog = useCallback(
    async (event) => {
      event.stopPropagation();
      const dialogProps = {
        id: 'delete-datastore',
        component: 'div',
        labels: {
          title: t('commoncomponents.datastore.dialogs.delete.title', 'Delete datastore?'),
          body: (
            <Trans
              i18nKey="commoncomponents.datastore.dialogs.delete.body"
              defaultValue="Do you really want to delete <i>{{datasetName}}</i>?
              This action is irreversible"
              values={{ datasetName: dataset?.name }}
              shouldUnescape={true}
            />
          ),
          button1: t('commoncomponents.datasetmanager.dialogs.cancel', 'Cancel'),
          button2: t('commoncomponents.datasetmanager.dialogs.delete.confirmButton', 'Delete'),
        },
        button2Props: {
          color: 'error',
        },
      };
      const result = await TwoActionsDialogService.openDialog(dialogProps);
      if (result === 2) {
        deleteDataStore();
      }
    },
    [t, deleteDataStore, dataset]
  );

  return (
    <FadingTooltip title="Supprimer le magasin de données">
      <IconButton color="primary" onClick={askConfirmationToDeleteDialog} disabled={disabled}>
        <DeleteOutlined fontSize="large" />
      </IconButton>
    </FadingTooltip>
  );
};
