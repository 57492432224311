import { t } from 'i18next';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { Api } from '../../../../services/config/Api';
import { DATASET_ACTIONS_KEY } from '../../../commons/DatasetConstants';
import { DATASTORE_ACTIONS } from '../../../commons/DatastoreConstants';
import { RUNNER_ACTIONS_KEY } from '../../../commons/RunnerConstants';
import { dispatchSetApplicationErrorMessage } from '../../../dispatchers/app/ApplicationDispatcher';

function* getRunnerLastRun(runner, organizationId, workspaceId) {
  if (!runner) return;

  let lastRun;
  if (runner.lastRun) {
    const res = yield call(Api.RunnerRuns.getRun, organizationId, workspaceId, runner.id, runner.lastRun.runnerRunId);
    lastRun = res.data;
  }

  const namesMap = { datastore_checking: 'validation', push_datastore: 'push' };
  return {
    id: runner.id,
    lastRunState: lastRun?.state ?? null,
    lastRunId: lastRun?.id ?? null,
    lastRunDate: lastRun?.createdAt ?? null,
    name: namesMap[runner.runTemplateId],
  };
}

const getDatastoreRunners = (state) => state.datastore.runners;
const getDataset = (state, datasetId) => state.dataset.list.data.find((dataset) => dataset.id === datasetId);

export function* updateDataStoreAssociatedRunners(action) {
  try {
    const organizationId = action.organizationId;
    const workspaceId = action.workspaceId;
    const datastoreRunners = yield select(getDatastoreRunners);

    const isTargetDatasetId = (param) => param.parameterId === 'source_dataset_id' && param.value === action.datasetId;
    const isTargetDatasetRunner = (runner) => runner.parametersValues.some(isTargetDatasetId);
    let validationRunner = datastoreRunners
      .filter((runner) => runner.runTemplateId === 'datastore_checking')
      .find(isTargetDatasetRunner);
    let pushRunner = datastoreRunners
      .filter((runner) => runner.runTemplateId === 'push_datastore')
      .find(isTargetDatasetRunner);

    const newRuns = action.newRuns; // Should be defined if runners have been created before calling the saga
    if (validationRunner && newRuns?.validation)
      validationRunner = { ...validationRunner, lastRun: newRuns.validation };
    if (pushRunner && newRuns?.push) pushRunner = { ...pushRunner, lastRun: newRuns.push };

    if (validationRunner === undefined || pushRunner === undefined) {
      yield put(
        dispatchSetApplicationErrorMessage(
          { title: t('commoncomponents.banner.corruptedDatastore', 'Corrupted datastore'), status: null },
          t('commoncomponents.banner.datastoreRunnerNotFound', 'At least one of the associated runners is missing')
        )
      );
    }

    // runner state is currently not handled properly, need to fetch last run state instead
    const validationRunnerInfos = yield call(getRunnerLastRun, validationRunner, organizationId, workspaceId);
    const pushRunnerInfos = yield call(getRunnerLastRun, pushRunner, organizationId, workspaceId);

    const dataset = yield select(getDataset, action.datasetId);
    const { associatedRunners: prevAssociatedRunners } = dataset;

    // updating only if no previous associated runner found
    // or if the state of the run changed in order not to trigger dataset object update
    let updateAssociatedRunnersInStore = false;
    for (const runnerInfos of [validationRunnerInfos, pushRunnerInfos]) {
      if (!runnerInfos) continue;
      if (!prevAssociatedRunners || prevAssociatedRunners[runnerInfos.name]?.state !== runnerInfos.lastRunState) {
        updateAssociatedRunnersInStore = true;
      }
    }

    // Patch associatedRunners in list of datasets in redux
    if (updateAssociatedRunnersInStore) {
      yield put({
        type: DATASET_ACTIONS_KEY.UPDATE_DATASET,
        datasetId: action.datasetId,
        datasetData: {
          associatedRunners: {
            validation: {
              id: validationRunnerInfos?.id,
              lastRunId: validationRunnerInfos?.lastRunId,
              lastRunDate: validationRunnerInfos?.lastRunDate,
              state: validationRunnerInfos?.lastRunState,
            },
            push: {
              id: pushRunnerInfos?.id,
              lastRunId: pushRunnerInfos?.lastRunId,
              lastRunDate: pushRunnerInfos?.lastRunDate,
              state: pushRunnerInfos?.lastRunState,
            },
          },
        },
      });
    }

    // Start runners polling if they are currently running
    if (validationRunnerInfos?.lastRunState === 'Running')
      yield put({
        type: DATASTORE_ACTIONS.START_RUNNER_STATUS_POLLING,
        datasetId: action.datasetId,
        runnerType: 'validation',
        runnerId: validationRunnerInfos?.id,
        runId: validationRunnerInfos?.lastRunId,
      });
    if (pushRunnerInfos?.lastRunState === 'Running')
      yield put({
        type: DATASTORE_ACTIONS.START_RUNNER_STATUS_POLLING,
        datasetId: action.datasetId,
        runnerType: 'push',
        runnerId: pushRunnerInfos?.id,
        runId: pushRunnerInfos?.lastRunId,
      });
  } catch (error) {
    console.error(error);
    yield put(
      dispatchSetApplicationErrorMessage(
        error,
        t('commoncomponents.banner.runnerNotUpdated', "Runner hasn't been updated")
      )
    );
  }
}

export function* updateDataStoreAssociatedRunnersSaga() {
  yield takeEvery(
    RUNNER_ACTIONS_KEY.TRIGGER_SAGA_UPDATE_DATASTORE_ASSOCIATED_RUNNERS,
    updateDataStoreAssociatedRunners
  );
}
