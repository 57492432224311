import React, { useCallback, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Api } from '../../../services/config/Api';
import { SCENARIO_RUN_STATE, SCENARIO_VALIDATION_STATUS } from '../../../services/config/ApiConstants';
import { ACL_PERMISSIONS } from '../../../services/config/accessControl';
import { TwoActionsDialogService } from '../../../services/twoActionsDialog/twoActionsDialogService';
import { WizardUtils } from '../../../utils/WizardUtils';
import EditableTable from '../components/EditableTable';
import GenericAutoCompleteInput from '../components/GenericAutoCompleteInput';
import { useWizard } from './WizardHook';
import ActionsImposeesTab from './tabs/ActionsImposeesTab';
import NiveauPriorisationTab from './tabs/NiveauPriorisationTab';
import ReglesGestionTab from './tabs/ReglesGestionTab';
import SequencesActionsTab from './tabs/SequencesActionsTab';
import SousGisementsTab from './tabs/SousGisementsTab';

const gestionActivitesColumnsList = [
  'ReglesGestion',
  'CriteresDeclenchement',
  'Impacts',
  'Gisement',
  'ClasseActif',
  'SousGisements',
  'id',
  'CoutsEtDureesActions',
  'SequencesActions',
];

const Wizard = ({ isWizardOpen, handleCloseWizard }) => {
  const methods = useForm({ defaultValues: {}, mode: 'onChange' });
  const { dirtyFields } = methods.formState;
  const isDirty = Object.keys(dirtyFields ?? {}).length !== 0;
  const [selectedGisement, setSelectedGisement] = useState('');
  const [selectedAssetClass, setSelectedAssetClass] = useState('');

  const {
    technicalPolicyData,
    getGisementData,
    datasetId,
    organizationId,
    scenarioData,
    saveData,
    classActifs,
    classActifByGisement,
    setNewTableNamesMapping,
    userPermissionsOnCurrentScenario,
    updateGisementData,
    status,
  } = useWizard(selectedGisement);

  const generateGestionActivitesRow = () => {
    const newRow = {};
    gestionActivitesColumnsList.forEach((column) => {
      if (['Gisement', 'id'].includes(column)) {
        newRow[column] = selectedGisement;
      } else if (column === 'ClasseActif') newRow[column] = selectedAssetClass;
      else {
        newRow[column] = selectedGisement + column;
      }
    });
    return newRow;
  };

  const saveWizardData = () => {
    const values = methods.getValues();
    const reset = methods.reset;
    if (selectedGisement && !Object.keys(technicalPolicyData?.gisements).includes(selectedGisement)) {
      const newGestionActivites = generateGestionActivitesRow();
      setNewTableNamesMapping(scenarioData.id, selectedGisement, newGestionActivites);
      updateGisementData(scenarioData.id, selectedAssetClassList, 'ClasseActif', selectedGisement);
      saveData(values, reset, newGestionActivites);
    } else saveData(values, reset);
  };
  const gisementsList = useMemo(() => {
    return Object.keys(technicalPolicyData?.gisements ?? {});
  }, [technicalPolicyData]);

  const [gisementOptions, setGisementOptions] = useState(gisementsList);

  const assetClassByGisement = useMemo(() => {
    return technicalPolicyData?.gisements[selectedGisement]?.tableNamesMapping?.ClasseActif;
  }, [selectedGisement, technicalPolicyData]);

  const [selectedAssetClassList, setSelectedAssetClassList] = useState([]);
  const [loadingClassActifAttributs, setLoadingClassActifAttributs] = useState(false);
  const classActifsList = useMemo(() => {
    return classActifs?.map((classActif) => classActif.NiveauHierarchique);
  }, [classActifs]);

  const calculateClassActifAttributes = (classActif) => {
    if (!classActif) return;
    const attributsList = classActif
      .map((actif) =>
        Object.keys(actif)?.filter(
          (attr) =>
            ![
              'AgeApparent',
              'AgeApparentConnu',
              'DateDerniereAction',
              'DateDerniereInspection',
              'DateInstallation',
              'FamilleEquipement',
              'id',
              'Identifiant',
            ].includes(attr)
        )
      )
      .flat();
    return Array.from(new Set(attributsList));
  };

  const assetClassComponent = useMemo(() => {
    if (assetClassByGisement) return <TextField value={assetClassByGisement} disabled />;
    else
      return (
        <FormControl>
          <InputLabel id="class-actif">Classe d&apos;actif</InputLabel>
          <Select
            sx={{ minWidth: 200 }}
            label="Classe d'actifs"
            labelId="class-actif"
            value={selectedAssetClass}
            onChange={async (e) => {
              try {
                setLoadingClassActifAttributs(true);
                const { data } = await Api.Datasets.twingraphQuery(organizationId, datasetId, {
                  query: WizardUtils.buildQueryString(e.target.value),
                });
                const actifList = data.map((nh) => nh.n.properties);
                setSelectedAssetClass(e.target.value);
                setSelectedAssetClassList(actifList);
                setLoadingClassActifAttributs(false);
              } catch (error) {
                console.error(error);
                setLoadingClassActifAttributs(false);
              }
            }}
            options={classActifsList}
          >
            {classActifsList?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
  }, [assetClassByGisement, classActifsList, datasetId, organizationId, selectedAssetClass]);

  const [activeStep, setActiveStep] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedRegle, setSelectedRegle] = useState('');
  const [selectedSousGisement, setSelectedSousGisement] = useState('');
  const [selectedSequenceAction, setSelectedSequenceAction] = useState('');
  const [selectedActionImposee, setSelectedActionImposee] = useState('');
  const hasUserWritePermission = userPermissionsOnCurrentScenario.includes(ACL_PERMISSIONS.SCENARIO.WRITE);
  const isCurrentScenarioRunning =
    scenarioData?.state === SCENARIO_RUN_STATE.RUNNING ||
    scenarioData?.state === SCENARIO_RUN_STATE.DATA_INGESTION_IN_PROGRESS;
  const isCurrentScenarioRejected = scenarioData?.validationStatus === SCENARIO_VALIDATION_STATUS.REJECTED;
  const isCurrentScenarioValidated = scenarioData?.validationStatus === SCENARIO_VALIDATION_STATUS.VALIDATED;

  const editMode = !(
    isCurrentScenarioRunning ||
    isCurrentScenarioRejected ||
    isCurrentScenarioValidated ||
    !hasUserWritePermission
  );

  const handleNext = () => {
    if (selectedGisement) {
      if (!Object.keys(technicalPolicyData?.gisements).includes(selectedGisement)) {
        const newGestionActivites = generateGestionActivitesRow();
        setNewTableNamesMapping(scenarioData.id, selectedGisement, newGestionActivites);
        updateGisementData(scenarioData.id, selectedAssetClassList, 'ClasseActif', selectedGisement);
      } else if (Object.values(technicalPolicyData?.gisements[selectedGisement]).includes(null)) {
        const selectedGisementTablesMapping = technicalPolicyData?.gisements[selectedGisement].tableNamesMapping;
        getGisementData(selectedGisementTablesMapping, datasetId, scenarioData);
      }
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleTabChange = (event, newTab) => setActiveTab(newTab);

  const handleGisementChange = (newGisement) => {
    setSelectedGisement(newGisement);
  };

  const columns = [
    {
      field: 'Attribut',
      label: 'Attribut',
      inputType: 'select',
      options: calculateClassActifAttributes(classActifByGisement ?? selectedAssetClassList),
    },
    {
      field: 'Operateur',
      label: 'Opérateur',
      inputType: 'select',
      options: [
        { label: 'IN', value: 'IN' },
        { label: '<', value: 'LT' },
        { label: '≤', value: 'LEQ' },
        { label: '>', value: 'GT' },
        { label: '≥', value: 'GEQ' },
      ],
    },
    { field: 'Valeurs', label: 'Valeur', inputType: 'text' },
  ];

  const handleDiscardChanges = useCallback(
    async (event) => {
      if (isDirty) {
        event.stopPropagation();
        const reset = methods.reset;
        const dialogProps = {
          id: 'discard-wizard-changes',
          labels: {
            title: 'Quitter sans sauvegarder ?',
            body: 'Toutes les modifications non sauvegardées seront perdues.',
            button1: "Retourner à l'édition",
            button2: 'Quitter',
          },
        };
        const result = await TwoActionsDialogService.openDialog(dialogProps);
        if (result === 2) {
          reset({});
          setActiveStep(0);
          setActiveTab(0);
          setSelectedGisement('');
          setSelectedRegle('');
          setSelectedSousGisement('');
          setSelectedSequenceAction('');
          setSelectedActionImposee('');
          handleCloseWizard();
          setGisementOptions(gisementsList);
        }
      } else {
        setActiveStep(0);
        setActiveTab(0);
        setSelectedGisement('');
        setSelectedRegle('');
        setSelectedSousGisement('');
        setSelectedSequenceAction('');
        setSelectedActionImposee('');
        setGisementOptions(gisementsList);
        handleCloseWizard();
      }
    },
    [gisementsList, handleCloseWizard, isDirty, methods.reset]
  );

  return (
    <FormProvider {...methods} key={`form-${scenarioData.id}-wizard`}>
      <div>
        <Dialog open={isWizardOpen} onClose={handleCloseWizard} maxWidth="xl" disableEscapeKeyDown={true}>
          <Backdrop open={status === 'SAVING'}>
            <CircularProgress color="inherit" />
            <Typography variant="h4">Sauvegarde en cours...</Typography>
          </Backdrop>
          <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Gestion des Activités - Wizard
            </Typography>
            {activeStep > 1 && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <TextField value={selectedGisement} disabled sx={{ minWidth: 200, marginTop: 2 }}></TextField>
                <TextField value={assetClassByGisement} disabled sx={{ minWidth: 200, marginTop: 2 }}></TextField>
              </Box>
            )}
          </Box>
          <DialogContent>
            <Box sx={{ width: '100%', mt: 2 }}>
              {activeStep === 0 && (
                <>
                  <NiveauPriorisationTab editMode={editMode} />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button onClick={handleDiscardChanges} variant="outlined" color="secondary">
                      {isDirty ? 'Annuler' : 'Fermer'}
                    </Button>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                        variant="contained"
                        color="primary"
                      >
                        Suivant
                      </Button>
                      {editMode && (
                        <Button disabled={!isDirty} onClick={saveWizardData} variant="contained" color="primary">
                          Sauvegarder
                        </Button>
                      )}
                    </Box>
                  </Box>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <Grid container sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <GenericAutoCompleteInput
                      onChange={(e, newGisement) => {
                        handleGisementChange(newGisement);
                      }}
                      value={selectedGisement ?? ''}
                      onNewOption={(newGisement) => {
                        setSelectedGisement(newGisement);
                        setGisementOptions((prevOptions) => [...prevOptions, newGisement]);
                      }}
                      options={gisementOptions}
                      label={'Sélectionner ou créer un gisement'}
                      editMode={editMode}
                    />
                    {assetClassComponent}
                  </Grid>
                  <Controller
                    name={selectedGisement ?? ''}
                    render={({ field }) => {
                      const { value, onChange } = field;
                      return loadingClassActifAttributs ? (
                        <Skeleton width="100%" animation="wave">
                          <EditableTable columns={[]} rows={[]} onChange={onChange} />
                        </Skeleton>
                      ) : (
                        <EditableTable
                          columns={columns}
                          rows={value ?? technicalPolicyData?.gisements[selectedGisement]?.Gisement ?? []}
                          editMode={editMode && !!selectedGisement && (!!assetClassByGisement || !!selectedAssetClass)}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button onClick={handleDiscardChanges} variant="outlined" color="secondary">
                      {isDirty ? 'Annuler' : 'Fermer'}
                    </Button>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        disabled={
                          !(selectedGisement && (assetClassByGisement || selectedAssetClass)) ||
                          loadingClassActifAttributs
                        }
                        onClick={handleNext}
                        variant="contained"
                        color="primary"
                      >
                        Suivant
                      </Button>
                      {editMode && (
                        <Button
                          onClick={saveWizardData}
                          variant="contained"
                          color="primary"
                          disabled={
                            !(selectedGisement && (assetClassByGisement || selectedAssetClass)) ||
                            loadingClassActifAttributs ||
                            !isDirty
                          }
                        >
                          Sauvegarder
                        </Button>
                      )}
                    </Box>
                  </Box>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <Tabs value={activeTab} onChange={handleTabChange} centered>
                    <Tab label={'Règles de Gestion'} />
                    <Tab label={'Sous-Gisements'} />
                    <Tab label={"Séquences d'Actions"} />
                    <Tab label={'Actions Imposées'} />
                  </Tabs>
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                    {activeTab === 0 && (
                      <ReglesGestionTab
                        selectedGisement={selectedGisement}
                        selectedRegle={selectedRegle}
                        setSelectedRegle={setSelectedRegle}
                        editMode={editMode}
                      />
                    )}
                    {activeTab === 1 && (
                      <SousGisementsTab
                        selectedGisement={selectedGisement}
                        selectedSousGisement={selectedSousGisement}
                        setSelectedSousGisement={setSelectedSousGisement}
                        editMode={editMode}
                      />
                    )}
                    {activeTab === 2 && (
                      <SequencesActionsTab
                        selectedGisement={selectedGisement}
                        selectedSequenceAction={selectedSequenceAction}
                        setSelectedSequenceAction={setSelectedSequenceAction}
                        editMode={editMode}
                      />
                    )}
                    {activeTab === 3 && (
                      <ActionsImposeesTab
                        selectedActionImposee={selectedActionImposee}
                        setSelectedActionImposee={setSelectedActionImposee}
                        selectedGisement={selectedGisement}
                        editMode={editMode}
                      />
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button onClick={handleDiscardChanges} variant="outlined" color="secondary">
                        {isDirty ? 'Annuler' : 'Fermer'}
                      </Button>
                    </Box>
                    <Box>
                      {editMode && (
                        <Button disabled={!isDirty} onClick={saveWizardData} variant="contained" color="primary">
                          Sauvegarder
                        </Button>
                      )}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </FormProvider>
  );
};
Wizard.propTypes = {
  isWizardOpen: PropTypes.bool,
  handleCloseWizard: PropTypes.func,
};
export default Wizard;
