import { t } from 'i18next';
import { call, takeEvery, put, select } from 'redux-saga/effects';
import { Api } from '../../../../services/config/Api';
import { DATASET_ACTIONS_KEY } from '../../../commons/DatasetConstants';
import { DATASTORE_ACTIONS } from '../../../commons/DatastoreConstants';
import { dispatchSetApplicationErrorMessage } from '../../../dispatchers/app/ApplicationDispatcher';
// eslint-disable-next-line max-len
import { updateDataStoreAssociatedRunners } from '../../runner/UpdateDatastoreAssociatedRunners/UpdateDataStoreAssociatedRunners';

const getWorkspaceId = (state) => state.workspace.current?.data?.id;
const getOrganizationId = (state) => state.organization.current?.data?.id;
const getSelectedDatasetId = (state) => state.datastore?.selectedDatasetId;

/*
Action parameters:
- runnerId: id of the associated runner to launch
- runnerType: type of the associated runner (value must be 'push' or 'validation')
*/
export function* startDatastoreRunner(action) {
  const workspaceId = yield select(getWorkspaceId);
  const organizationId = yield select(getOrganizationId);
  const currentDatasetId = yield select(getSelectedDatasetId);

  yield put({ type: DATASTORE_ACTIONS.SET_LOADING, loading: true });
  const { data: run } = yield call(Api.Runners.startRun, organizationId, workspaceId, action.runnerId);
  yield put({ type: DATASTORE_ACTIONS.SET_LOADING, loading: false });
  yield put({ type: DATASTORE_ACTIONS.UPDATE_RUNNER, runnerId: action.runnerId, runnerData: { lastRun: run } });
  yield put({
    type: DATASET_ACTIONS_KEY.UPDATE_DATASTORE_ASSOCIATED_RUNNERS,
    datasetId: currentDatasetId,
    associatedRunnersPatch: {
      [action.runnerType]: {
        id: action.runnerId,
        lastRunId: run.runnerRunId,
        lastRunDate: run.createdAt,
        state: 'Running',
      },
    },
  });
  yield put({
    type: DATASTORE_ACTIONS.START_RUNNER_STATUS_POLLING,
    datasetId: currentDatasetId,
    runnerType: action.runnerType,
    runnerId: action.runnerId,
    runId: run.runnerRunId,
  });

  if (!['push', 'validation'].includes(action.runnerType))
    console.warn(`Runner type "${action.runnerType}" is not supported`);

  try {
    yield call(updateDataStoreAssociatedRunners, {
      organizationId,
      workspaceId,
      datasetId: currentDatasetId,
      newRuns: { [action.runnerType]: run },
    });
  } catch (error) {
    console.error(error);
    yield put(
      dispatchSetApplicationErrorMessage(
        error,
        t('commoncomponents.datastore.errors.runnerStart', 'A problem occurred when starting runner.')
      )
    );
  }
}

function* startDatastoreRunnerSaga() {
  yield takeEvery(DATASTORE_ACTIONS.TRIGGER_SAGA_START_DATASTORE_RUNNER, startDatastoreRunner);
}

export default startDatastoreRunnerSaga;
