import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { useSelectedDataset, useValidationState } from '../../../../state/hooks/DatastoreHooks';

const CHIP_COLOR = {
  draft: 'primary',
  unknown: 'primary',
  modified: 'tertiary',
  valid: 'success',
  rejected: 'error',
  running: 'info',
};

export const StatusChip = () => {
  const { t } = useTranslation();
  const dataset = useSelectedDataset();
  const validation = useValidationState();

  const isVisible = dataset?.id != null && Object.keys(CHIP_COLOR).includes(validation?.status);
  const chipColor = CHIP_COLOR[validation?.status];

  return (
    isVisible && (
      <Chip
        key={`${dataset?.id}_${validation?.status}`}
        label={t(`commoncomponents.datastore.details.status.${validation?.status}`, 'Inconnu').toUpperCase()}
        color={chipColor ?? 'primary'}
        size="medium"
        sx={{ ml: 2 }}
      />
    )
  );
};
