import { useCallback } from 'react';
import RunnerRunService from '../../../../../../services/runnerRun/RunnerRunService';
import { useOrganizationId } from '../../../../../../state/hooks/OrganizationHooks';
import { useWorkspaceId } from '../../../../../../state/hooks/WorkspaceHooks';

export const useDownloadRunLogs = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  return useCallback(
    (runnerId, runnerRunId) => RunnerRunService.downloadLogsFile(organizationId, workspaceId, runnerId, runnerRunId),
    [organizationId, workspaceId]
  );
};
