import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { TwoActionsDialogService } from '../../../../services/twoActionsDialog/twoActionsDialogService';
import GenericAutoCompleteInput from '../../components/GenericAutoCompleteInput';
import { useActionsImposees } from './hooks/ActionsImposeesHook';

const ActionsImposeesTab = ({ selectedActionImposee, setSelectedActionImposee, selectedGisement, editMode }) => {
  const { actionsImposees, tableNamesMapping, classActif, sequenceActionsList, niveauDePriorisation } =
    useActionsImposees(selectedGisement);
  const [actionImposeeOptions, setActionImposeeOptions] = useState(actionsImposees?.map((ai) => ai.Identifiant) ?? []);

  const handleAddActionImposee = (newActionImposee) => {
    setSelectedActionImposee(newActionImposee);
    setActionImposeeOptions((prevOptions) => [...(prevOptions ?? []), newActionImposee]);
  };

  const calculateNewValue = (value, changedValue, field) => {
    if (!value.map((action) => action.Identifiant).includes(selectedActionImposee)) {
      return [
        ...value,
        {
          // Init all mandatory columns with default values for a new imposed action
          // TODO: add check for mandatory columns in corresponding E2E test?
          Identifiant: selectedActionImposee,
          SequenceActions: sequenceActionsList?.[0],
          Groupe: classActif?.[0]?.Identifiant,
          DateRealisation: new Date().toISOString().split('T')[0],
          NiveauDePriorisation: niveauDePriorisation?.[0] ?? '',
          CategoriesBudgetaires: '',
          // update the changed column
          [field]: changedValue,
        },
      ];
    }
    return value.map((action) => {
      if (action.Identifiant === selectedActionImposee) return { ...action, [field]: changedValue };
      return action;
    });
  };

  const handleDeleteActionImposee = async (event) => {
    if (selectedActionImposee) {
      event.stopPropagation();
      const dialogProps = {
        id: 'delete-action-imposee',
        labels: {
          title: "Supprimer l'action imposée ?",
          body: '',
          button1: 'Annuler',
          button2: 'Supprimer',
        },
      };
      const result = await TwoActionsDialogService.openDialog(dialogProps);
      if (result === 2) setSelectedActionImposee('');
      return result;
    }
  };
  if (sequenceActionsList?.length === 0)
    return (
      <Typography>Pour modifier les actions imposées, il faut créer au moins une séquence d&apos;action</Typography>
    );
  return (
    <Box width="100%">
      <Controller
        name={tableNamesMapping?.SequencesActionsImposees || `${selectedGisement}_SequencesActionsImposees`}
        defaultValue={actionsImposees ?? []}
        render={({ field }) => {
          const { value, onChange } = field;
          return (
            <>
              <Grid container spacing={2} sx={{ pl: 2 }}>
                <GenericAutoCompleteInput
                  onChange={(e, newAction) => setSelectedActionImposee(newAction || '')}
                  onNewOption={handleAddActionImposee}
                  value={selectedActionImposee || ''}
                  options={actionImposeeOptions}
                  label="Sélectionner ou créer une action imposée"
                  data-testid="input-sélectionner-ou-créer-une-action-imposée"
                  editMode={editMode}
                />
                {selectedActionImposee && editMode && (
                  <IconButton
                    onClick={async (event) => {
                      const response = await handleDeleteActionImposee(event);
                      if (response === 2) {
                        const filteredValue = value.filter((ai) => ai.Identifiant !== selectedActionImposee);
                        onChange(filteredValue);
                        setActionImposeeOptions(filteredValue?.map((ai) => ai.Identifiant));
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={6}>
                  <GenericAutoCompleteInput
                    label="Groupe d’Équipements"
                    value={value.find((action) => action.Identifiant === selectedActionImposee)?.Groupe || ''}
                    options={classActif?.map((ca) => ca.Identifiant) ?? []}
                    onChange={(event, newValue) => {
                      onChange(calculateNewValue(value, newValue, 'Groupe'));
                    }}
                    disabled={!editMode || !selectedActionImposee}
                    fullWidth
                    inputProps={{ 'data-testid': 'input-groupe-equipements' }}
                    editMode={editMode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <GenericAutoCompleteInput
                    label="Séquence d'Actions"
                    value={value?.find((action) => action.Identifiant === selectedActionImposee)?.SequenceActions || ''}
                    options={sequenceActionsList}
                    disabled={!editMode || !selectedActionImposee}
                    onChange={(event, newValue) => {
                      onChange(calculateNewValue(value, newValue, 'SequenceActions'));
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Date de Réalisation"
                    type="date"
                    value={value.find((action) => action.Identifiant === selectedActionImposee)?.DateRealisation || ''}
                    onChange={(e) => {
                      const changedValue = e.target.value;
                      onChange(calculateNewValue(value, changedValue, 'DateRealisation'));
                    }}
                    fullWidth
                    disabled={!editMode || !selectedActionImposee}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ 'data-testid': 'input-date-realisation' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Projet"
                    value={value.find((action) => action.Identifiant === selectedActionImposee)?.Projet || ''}
                    onChange={(e) => {
                      const changedValue = e.target.value;
                      onChange(calculateNewValue(value, changedValue, 'Projet'));
                    }}
                    disabled={!editMode || !selectedActionImposee}
                    fullWidth
                    inputProps={{ 'data-testid': 'input-projet' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Catégories Budgétaires"
                    value={
                      value.find((action) => action.Identifiant === selectedActionImposee)?.CategoriesBudgetaires || ''
                    }
                    onChange={(e) => {
                      const changedValue = e.target.value;
                      onChange(calculateNewValue(value, changedValue, 'CategoriesBudgetaires'));
                    }}
                    disabled={!editMode || !selectedActionImposee}
                    fullWidth
                    inputProps={{ 'data-testid': 'input-categories-budgetaires' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl sx={{ minWidth: '100%' }}>
                    <InputLabel id="niveau-priorisation">Niveau de priorisation</InputLabel>
                    <Select
                      label="niveau-priorisation"
                      labelId="Niveau de priorisation"
                      disabled={!editMode || !selectedActionImposee}
                      value={
                        value.find((action) => action.Identifiant === selectedActionImposee)?.NiveauDePriorisation || ''
                      }
                      onChange={(e) => {
                        const changedValue = e.target.value;
                        onChange(calculateNewValue(value, changedValue, 'NiveauDePriorisation'));
                      }}
                      fullWidth
                      inputProps={{ 'data-testid': 'input-niveau-de-priorisation' }}
                    >
                      <MenuItem key="none" value="">
                        Aucun
                      </MenuItem>
                      {niveauDePriorisation.map((np) => (
                        <MenuItem key={np} value={np}>
                          {np}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          );
        }}
      />
    </Box>
  );
};

ActionsImposeesTab.propTypes = {
  selectedActionImposee: PropTypes.string.isRequired,
  setSelectedActionImposee: PropTypes.func.isRequired,
  selectedGisement: PropTypes.string,
  editMode: PropTypes.bool,
};

export default ActionsImposeesTab;
